body {
  position: relative;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --color-ci-red: #990000;
  --color-ci-blue: blue;
  --color-ci-danger: #990000;
  --color-ci-red-hover: #460000;
  --color-ci-grey: #ddd;
  --color-ci-white: white;
  --top-bar-height: 20px;
}

@media only screen and (max-width: 600px) {
  nav {
    display: flex;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
strong,
a {
  font-family: "Helvetica", Helvetica, Arial, sans-serif;
  text-decoration: none;
}

::selection {
  background-color: var(--color-ci-red);
  color: var(--color-ci-white);
}

a:hover {
  color: var(--color-ci-red-hover);
}

h1 {
  font-size: 3.5rem;
  font-weight: bold;
}

h2 {
  font-size: 2rem;
  font-weight: bold;
}

h3 {
  font-size: 1rem;
  font-weight: bold;
}

/*CHECK*/
section {
  background-color: #bfbfbf;
  padding: 7vh 0;
}

img {
  width: 100%;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 102vh;
}

.content-wrapper {
  flex: 1;
}

.img-agb {
  height: 5%;
  width: 10%;
}

.background-image {
  background-image: url("assets/verwaltungNeu3.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 15vh 0;
}

.btn {
  border-radius: 0px;
  font-weight: 600;
}

.company-name {
  font-weight: 700;
  font-style: italic;
}

.btn-product {
  width: 25%;
}

.section-secondary {
  background: #bfbfbf;
  color: black;
}

.group-effect:hover {
  color: #990000;
}

.card-title-secondary {
  text-shadow: 0px 0px 0px;
  margin-top: 13%;
}

.card-width {
  align-items: center;
  justify-content: center;
}

.navbar-text {
  background: rgba(255, 255, 255, 0.9);
  top: var(--top-bar-height);
  text-decoration: none;
  color: #000000;
}

a.dropdown-toggle.nav-link {
  font-size: 16px;
}

nav h6.my-1 {
  font-size: 16px;
}

.navbar-text .dropdown-item:active {
  background-color: inherit;
  color: var(--color-ci-red);
}

nav div span a h6 {
  font-weight: bold;
}

.dropdown-toggle {
  font-weight: bold;
  font-size: 15px;
}

.navbar a.active,
.navbar a:hover {
  color: var(--color-ci-red);
}

.sticky-nav {
  position: fixed;
  z-index: 999;
  width: 100%;
  top: 0;
}

.text-bold {
  font-weight: bold;
}

.none-decoration {
  text-decoration: none;
  color: #ffffff;
}

.transform-hover {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.product-card {
  border-radius: 0;
  color: #000000;
}

.transform-hover:hover {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);

  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;

  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.transform-hover::after {
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.card-img-top {
  border-radius: 0;
  background-size: 10px;
}

.product-details {
  color: white;
  background-color: gray;
}

.input-field-contact {
  max-width: 40%;
  border: 2px solid;
  border-radius: 0;
}

.contact-person-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.contact-person-list > .contact-person {
  flex: 1 1 auto;
  width: calc(50% - 20px);
}

.contact-person {
  position: relative;
}

.contact-person img {
  width: 70%;
}

.contact-person-details {
  position: absolute;
  background: #595959;
  bottom: 0;
  right: 0;
  padding: 10px;
}

.contact-person-details svg {
  margin-right: 10px;
}

.contact-person-details a {
  text-decoration: none;
  color: inherit;
}

.contact-person-link:hover {
  color: var(--color-ci-red);
}

/*.recommendations {*/
/*}*/

.recommendations:hover {
  cursor: pointer;
  transition: 0.3s;
  opacity: 0.3;
}

.about-us {
  line-height: 1.6rem;
  text-align: justify;
}

.margin-right-zero {
  margin-right: 0px;
}

.about-us > h1 {
  font-weight: normal;
  text-shadow: 0px 2px 3px;
}

.contact-form-title {
  text-shadow: 0px 2px 3px;
}

.top-bar {
  top: 0;
  z-index: 9999;
  background-color: var(--color-ci-red);
}

.form {
  border-radius: 0px;
  border: 2px solid black;
}

.top-bar span a {
  text-decoration: none !important;
  color: var(--color-ci-white);
}

.background-filter {
  position: absolute;
}

.product-section::after {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.company-run {
  height: 40%;
  object-fit: contain;
  position: absolute;
  top: 100%;
  left: -15%;
  rotate: calc(-7deg);
}

.company-information-card b {
  color: var(--color-ci-red);
  font-size: 2rem;
}

.red-star {
  color: var(--color-ci-danger);
  font-weight: bold;
}

.vl {
  border-left: 1px solid grey;
  height: 3vh;
}

.rotate-box {
  position: relative;
  right: 9px;
  transform: rotate(45deg);
  height: 20px;
  width: 20px;
}

.inner-box {
  position: relative;
  background-color: var(--color-ci-danger);
  top: 5px;
  left: 5px;
  height: 10px;
  width: 10px;
}

.timeline-year {
  color: var(--color-ci-danger);
}

.timeline-text {
  font-weight: 600;
}

.timeline-image {
  position: absolute;
  left: -2vw;
  aspect-ratio: 16/9;
  height: 7vh;
  object-fit: contain;
  text-align: left;
}

.attachment:hover {
  cursor: pointer;
}

.scroll-to-top-button {
  position: fixed;
  right: 5vw;
  bottom: 4vh;
  width: 50px;
  height: 60px;
  background-color: var(--color-ci-red);
  color: white;
  border-radius: 10%;
  cursor: pointer;
  z-index: 999;
}

.data-table-row {
  cursor: pointer;
}

.background-white {
  background-color: white;
}

.opener-text {
  font-size: 2em;
}

.player-wrapper {
  width: auto;
  height: auto;
}

.react-player {
  padding-top: 56.25%;
  position: relative;
}

.react-player > div {
  position: absolute;
}

.ul-scrollable {
  font-size: 16px;
  position: absolute;
  max-height: 300px;
  max-width: 600px;
  overflow-y: scroll;
  white-space: initial;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  list-style-type: none;
  scroll-behavior: smooth;
}

.selected-item {
  color: var(--color-ci-red);
  background-color: rgba(0, 0, 0, 0.1);
}

.hint-box {
  height: 30px;
  width: 300px;
  font-size: 12px;
  position: fixed;
  color: rgba(0, 0, 0, 0);
}

.hint-box:hover,
.hint-box:focus {
  color: rgba(0, 0, 0, 0.3);
  transition: ease;
}

.nav-ul-scrollable {
  overflow-y: scroll;
  max-height: 400px;
  max-width: 600px;
  white-space: initial;
  list-style-type: none;
}

.nav-ul-scrollable:hover {
  cursor: pointer;
  color: #990000;
}

ul.product li {
  list-style-type: decimal-leading-zero;
}

.popup {
  position: fixed;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.dont-show {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9998;
  background-color: white;
}

.contact-person-names {
  font-size: 1.1em;
}

.new-button {
  cursor: pointer;
  transition: all 0.2s ease;
  margin-left: 90px;
  margin-top: -45px;
}

.new-button-mobile {
  cursor: pointer;
  transition: all 0.2s ease;
}

.new-button:hover {
  transform: scale(1.2);
}

.contact-icon {
  filter: invert(91%) sepia(92%) saturate(4874%) hue-rotate(14deg)
    brightness(104%) contrast(123%);
  width: 50px;
}

.contact-icon-image {
  width: 20px !important;
}

[class|="col"] {
  margin-bottom: 20px;
}

footer {
  position: absolute;
  padding: 0px 10px 0px 10px;
  bottom: -20vh;
  width: 100%;
  color: white;
  background-color: var(--color-ci-danger);
}

main {
  margin-top: 115px;
}
